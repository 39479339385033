<template>
  <v-container>
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a while.
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-show="error"
      transition="fade-transition"
      color="error"
      type="error"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      {{ errorMessage }}
    </v-alert>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="6">
        <v-row>
          <v-col>
            <p class="text-h4">Welcome to our KYC Homepage!</p>
            <p class="grey--text">
              We're here to ensure the security and compliance of our platform
              for all users. To maintain the highest standards of trust and
              transparency, we kindly request that you complete the initial KYC
              (Know Your Customer) verification process before proceeding
              further.
            </p>

            <!-- initial -->
            <v-row>
              <v-col>
                <v-card @click="handleInitialKYC" class="mb-6">
                  <v-card-title>
                    <v-icon> mdi-rocket-launch-outline </v-icon>
                    <div class="ml-2">Initial</div>

                    <v-spacer />

                    <div v-if="!initial_data" class="red--text">Incomplete</div>
                    <v-icon v-if="!initial_data" class="ml-2 red--text">
                      mdi-progress-alert
                    </v-icon>

                    <div v-if="initial_data" class="green--text">Completed</div>
                    <v-icon v-if="initial_data" class="ml-2 green--text">
                      mdi-check-circle-outline
                    </v-icon>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <p class="grey--text">
                  After completing the Initial KYC, we request additional
                  information based on the accounts you have provided.
                </p>
              </v-col>
            </v-row>

            <v-divider class="mb-5" v-if="initial_data" />

            <!-- personal -->
            <v-row v-if="this.is_personal">
              <v-col>
                <p class="text-body-2 grey--text">
                  Since you are investing in mortgages using personal funds
                  (e.g., RRSP, TFSA, Cash). Please complete the personal KYC
                  form below.
                </p>

                <v-card @click="handlePersonalKYC" class="mb-6">
                  <v-card-title>
                    <v-icon> mdi-account-details </v-icon>
                    <div class="ml-2">Personal</div>

                    <v-spacer />

                    <div v-if="!personal_data" class="red--text">
                      Incomplete
                    </div>
                    <v-icon v-if="!personal_data" class="ml-2 red--text">
                      mdi-progress-alert
                    </v-icon>

                    <div v-if="personal_data" class="green--text">
                      Completed
                    </div>
                    <v-icon v-if="personal_data" class="ml-2 green--text">
                      mdi-check-circle-outline
                    </v-icon>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>

            <!-- corporation -->
            <v-row v-if="this.is_corporation">
              <v-col>
                <p class="text-body-2 grey--text">
                  Since you are investing in mortgages with funds from a
                  corporation you own. Please complete the following KYC on each
                  corporation listed below .
                </p>

                <v-card
                  v-for="account in private_corporation_accounts"
                  :key="account.id"
                  @click="
                    !account.frm_rsp
                      ? handleCorporationKYC('new', account.id)
                      : handleCorporationKYC(
                          'update',
                          account.id,
                          account.frm_rsp.id
                        )
                  "
                  class="mb-6"
                >
                  <v-card-title>
                    <v-icon> mdi-card-account-details-outline </v-icon>
                    <div class="ml-2">{{ account.name }}</div>

                    <v-spacer />

                    <div v-if="!account.frm_rsp" class="red--text">
                      Incomplete
                    </div>
                    <v-icon v-if="!account.frm_rsp" class="ml-2 red--text">
                      mdi-progress-alert
                    </v-icon>

                    <div v-if="account.frm_rsp" class="green--text">
                      Completed
                    </div>
                    <v-icon v-if="account.frm_rsp" class="ml-2 green--text">
                      mdi-check-circle-outline
                    </v-icon>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>

            <!-- mortgage company -->
            <v-row v-if="this.is_mortgage_company">
              <v-col>
                <p class="text-body-2 grey--text">
                  You choose to be investing in mortgages on behalf of a
                  corporation that is primarily in the business of lending money
                  via mortgages (e.g., Mortgage Investment Company, Mortgage
                  Brokerage). Please complete the company KYC form below.
                </p>

                <v-card
                  v-for="account in mortgage_company_accounts"
                  :key="account.id"
                  @click="
                    !account.frm_rsp
                      ? handleMortgageCompanyKYC('new', account.id)
                      : handleMortgageCompanyKYC(
                          'update',
                          account.id,
                          account.frm_rsp.id
                        )
                  "
                  class="mb-6"
                >
                  <v-card-title>
                    <v-icon> mdi-card-account-details-outline </v-icon>
                    <div class="ml-2">{{ account.name }}</div>

                    <v-spacer />

                    <div v-if="!account.frm_rsp" class="red--text">
                      Incomplete
                    </div>
                    <v-icon v-if="!account.frm_rsp" class="ml-2 red--text">
                      mdi-progress-alert
                    </v-icon>
                    <div v-if="account.frm_rsp" class="green--text">
                      Completed
                    </div>
                    <v-icon v-if="account.frm_rsp" class="ml-2 green--text">
                      mdi-check-circle-outline
                    </v-icon>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              @click="
                $router.push({
                  path: `/dashboard/admin/company/read/${company_id}`,
                })
              "
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "InitialKYC",

  props: ["company_id"],

  data: () => ({
    errorMessage: "",

    loading: false,
    error: false,
    is_initial_info_submitted: false,
    is_submitted: false,
    is_submitting: false,
    investor_kyc_is_completed: false,

    // for application type
    is_personal: false,
    is_corporation: false,
    is_mortgage_company: false,

    // for each account id
    corporation: null,
    mortgage_company: null,

    // for kyc data
    initial_data: null,
    personal_data: null,

    companyData: null,
  }),

  computed: {
    private_corporation_accounts() {
      return this.companyData.accounts.filter(
        (account) =>
          account.category === "Private" && account.type === "Corporation"
      );
    },

    mortgage_company_accounts() {
      return this.companyData.accounts.filter(
        (account) => account.category !== "Private"
      );
    },
  },

  methods: {
    handleInitialKYC() {
      if (!this.initial_data) {
        this.$router.push({
          path: `/dashboard/admin/form/initial-kyc-2024-v1/read/${this.company_id}`,
        });
      }

      if (this.initial_data) {
        this.$router.push({
          path: `/dashboard/admin/form/initial-kyc-2024-v1/read/${this.company_id}?response_id=${this.initial_data.id}`,
        });
      }
    },

    handlePersonalKYC() {
      if (!this.personal_data) {
        this.$router.push({
          path: `/dashboard/admin/form/personal-kyc-2024-v1/read/${this.company_id}`,
        });
      }
      if (this.personal_data) {
        this.$router.push({
          path: `/dashboard/admin/form/personal-kyc-2024-v1/read/${this.company_id}?response_id=${this.personal_data.id}`,
        });
      }
    },

    handleCorporationKYC(type, account_id, response_id) {
      // console.log("type: ", type);
      // console.log("account_id: ", account_id);
      // console.log("response_id: ", response_id);

      if (type === "new") {
        this.$router.push({
          path: `/dashboard/admin/form/corporation-kyc-2023-v1/read/${this.company_id}?account_id=${account_id}`,
        });
      }

      if (type === "update") {
        this.$router.push({
          path: `/dashboard/admin/form/corporation-kyc-2023-v1/read/${this.company_id}?account_id=${account_id}&response_id=${response_id}`,
        });
      }
    },

    handleMortgageCompanyKYC(type, account_id, response_id) {
      // console.log("type: ", type);
      // console.log("account_id: ", account_id);
      // console.log("response_id: ", response_id);

      if (type === "new") {
        this.$router.push({
          path: `/dashboard/admin/form/mortgage-company-kyc-2023-v1/read/${this.company_id}?account_id=${account_id}`,
        });
      }

      if (type === "update") {
        this.$router.push({
          path: `/dashboard/admin/form/mortgage-company-kyc-2023-v1/read/${this.company_id}?account_id=${account_id}&response_id=${response_id}`,
        });
      }
    },

    will_check_initial_response() {
      // find the section
      const section = this.initial_data.frm.frm_sects.find(
        (sect) => sect.title === "Investment Information"
      );

      // check if it is personal
      this.is_personal = section.frm_rsp_qs.some(
        (q) =>
          (q.frm_q.title ===
            "Are you investing in mortgages using personal funds (e.g., RRSP, TFSA, Cash)?" &&
            q.response === "Yes") ||
          (q.frm_q.title ===
            "Are you investing in mortgages with funds from a corporation you own?" &&
            q.response === "Yes")
      );

      // if (this.is_personal) console.log("Includes Personal KYC");

      // check if it is corporation
      this.is_corporation = section.frm_rsp_qs.some(
        (q) =>
          q.frm_q.title ===
            "Are you investing in mortgages with funds from a corporation you own?" &&
          q.response === "Yes"
      );

      // if (this.is_corporation) console.log("Includes Corporation KYC");

      // check if it is mortgage company
      this.is_mortgage_company = section.frm_rsp_qs.some(
        (q) =>
          q.frm_q.title ===
            "Are you investing in mortgages on behalf of a corporation that is primarily in the business of lending money via mortgages (e.g., Mortgage Investment Company, Mortgage Brokerage)?" &&
          q.response === "Yes"
      );

      // if (this.is_mortgage_company)
      //   console.log("Includes Mortgage Company KYC");
    },

    async getFormData() {
      try {
        this.loading = true;

        const company = await API().get(
          `/api/internal-admin/company/${this.company_id}`
        );

        // console.log("company data: ", company.data);
        this.companyData = company.data;

        // check if initial info was submitted
        const initial_kyc = company.data.frm_rsps.find(
          (frm_rsp) => frm_rsp.frm.unique_id === "initial-kyc-2024-v1"
        );

        // get initial kyc data
        if (initial_kyc) {
          const initial_kyc_frm_res = await API().get(
            `api/form/read_form_response?id=${initial_kyc.id}`
          );

          // console.log("Initial KYC Form data: ", initial_kyc_frm_res);

          this.initial_data = initial_kyc_frm_res.data;

          this.will_check_initial_response();
        }

        // check if personal info was submitted
        const personal_kyc = company.data.frm_rsps.find(
          (frm_rsp) => frm_rsp.frm.unique_id === "personal-kyc-2024-v1"
        );

        // get personal data
        if (personal_kyc) {
          const found_personal_data = await API().get(
            `api/form/read_form_response?id=${personal_kyc.id}`
          );

          // console.log("Personal KYC data: ", found_personal_data);
          this.personal_data = found_personal_data.data;
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        this.errorMessage = "Getting Form Data " + error.message;
        setTimeout(() => {
          this.pageIsError = false;
          this.errorMessage = "";
        }, 2000);
      }
    },
  },

  mounted() {
    this.getFormData();
  },
};
</script>

<style>
.bordered {
  border: 1px solid #444;
}
</style>
